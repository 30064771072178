import React from 'react';

import Icon from './Icon';

export default function ArticlesIcon({ className = '', text = 'Article' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H12.5858C13.1162 4 13.6249 4.21071 14 4.58579L17.4142 8C17.7893 8.37507 18 8.88378 18 9.41421V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6ZM8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H9C8.44772 13 8 12.5523 8 12ZM9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z" />
    </svg>
  </Icon>;
}
