import React from 'react';

import Icon from './Icon';

export default function TwitterIcon({ className, text = 'Twitter' }: { className?: string; text?: string;}): JSX.Element {
  return <Icon
    text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.362 8.23657C18.3717 8.37762 18.3717 8.51868 18.3717 8.66103C18.3717 12.9985 15.0697 18.001 9.03173 18.001V17.9984C7.2481 18.001 5.50152 17.4901 4 16.5268C4.25935 16.558 4.52001 16.5736 4.78131 16.5743C6.25944 16.5756 7.69531 16.0796 8.85818 15.1663C7.4535 15.1397 6.22173 14.2238 5.79143 12.8867C6.28349 12.9816 6.79049 12.9621 7.27345 12.8302C5.74203 12.5208 4.64026 11.1753 4.64026 9.61264C4.64026 9.59834 4.64026 9.58469 4.64026 9.57104C5.09657 9.8252 5.60748 9.96625 6.13008 9.98185C4.68771 9.01788 4.2431 7.09905 5.11412 5.59883C6.78074 7.64961 9.23973 8.89633 11.8794 9.02828C11.6149 7.88817 11.9763 6.69345 12.8291 5.89198C14.1512 4.64917 16.2306 4.71287 17.4734 6.03434C18.2086 5.88938 18.9132 5.61963 19.558 5.23742C19.3129 5.99729 18.8001 6.64275 18.115 7.0529C18.7656 6.9762 19.4013 6.802 20 6.53614C19.5593 7.19655 19.0042 7.77181 18.362 8.23657Z" />
    </svg>
  </Icon>;
}
