import React from 'react';

import Icon from './Icon';

export default function HomeIcon({ className = '', text = 'Home' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3875 12.6422C14.7422 12.4282 14.8563 11.9672 14.6423 11.6126C14.4283 11.2579 13.9673 11.1438 13.6127 11.3578C12.651 11.938 11.345 12.25 9.99936 12.25C8.65366 12.25 7.34838 11.938 6.38781 11.358C6.03323 11.1438 5.57221 11.2577 5.35809 11.6123C5.14397 11.9669 5.25784 12.4279 5.61242 12.642C6.85984 13.3953 8.44956 13.75 9.99936 13.75C11.5492 13.75 13.1393 13.3953 14.3875 12.6422Z" fill="currentColor" />
      <path d="M12.098 1.97015C10.8643 1.00985 9.1365 1.01012 7.90225 1.96995L2.56886 6.11838C1.73607 6.76561 1.25 7.76113 1.25 8.81524V15.3334C1.25 17.2205 2.77957 18.75 4.66667 18.75H15.3333C17.2204 18.75 18.75 17.2205 18.75 15.3334V8.81524C18.75 7.76113 18.2636 6.76538 17.4308 6.11814L12.098 1.97015ZM3.48981 7.30238L8.82308 3.15404C9.51594 2.61527 10.4853 2.61566 11.1769 3.15399L16.5105 7.30261C16.9771 7.66515 17.25 8.22328 17.25 8.81524V15.3334C17.25 16.392 16.392 17.25 15.3333 17.25H4.66667C3.60798 17.25 2.75 16.392 2.75 15.3334V8.81524C2.75 8.22328 3.02323 7.66492 3.48981 7.30238Z" fill="currentColor" />
    </svg>
  </Icon>;
}
